<template>
  <div class="main p-35">
    <div class="cut_off p-b-10 flex" v-if="isShow">
      <div class="font24 color-ffa flex-1">中医查询</div>
      <div class="flex-cen flex-1">
        <Input v-model="value" placeholder="搜索关键字...">
          <Icon type="ios-search" slot="prefix"
        /></Input>
        <div style="width: 50px" class="m-l-10 hand" @click="searchBtn()">搜索</div>
      </div>
    </div>
    <div v-if="isShow">
      <div class="flex-cen br-bot p-b-13 hand">
        <div style="display: flex; flex-wrap: wrap">
          <div
            class="m-t-18 zm_box"
            v-for="(item, i) in dataList"
            :key="i"
            @click="detBtn(item)"
          >
            <div class="font18 color333 a_con">{{ item.letter }}</div>
          </div>
        </div>
        <div v-if="iconDown" @click="detBtn()">
          <img src="../assets/images/icon_39 (1).png" alt="" />
        </div>
        <div v-else @click="detBtn()">
          <img src="../assets/images/icon_40.png" alt="" />
        </div>
      </div>
      <div v-if="isBox">
        <div>{{ letter }}</div>
        <div class="s_box">
          <div v-for="(item, i) in childList" :key="i">
            <div class="single hand" @click="dataIdBtn(item.dataId)">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isShowDetail">
      <div class="text-r font14 color999 hand" @click="returnBtn()">
        返回上一级
      </div>
      <div class="text-c font24 colorff9" v-html="detailShow.title"></div>
      <div v-html="detailShow.content"></div>
    </div>
  </div>
</template>

<script>
import { MedicineApi, contentDetailsApi } from "@/api/cyclo";
export default {
  data() {
    return {
      letter: "",
      value: "",
      iconDown: true,
      isBox: false,
      dataList: [],
      childList: [],
      isShow: true,
      isShowDetail: false,
      detailShow: {},
    };
  },
  methods: {
    getList() {
      MedicineApi({ searchKey: this.value, toolCode: "medicineFind" }).then(
        (res) => {
          this.dataList = res.data;
          this.childList = res.data.map((item) => {
            return item.child;
          });
        }
      );
    },
    // 搜索
    searchBtn() {
      MedicineApi({ searchKey: this.value, toolCode: "medicineFind" }).then(
        (res) => {
          this.iconDown = true;
          this.isBox = false;
          this.dataList = res.data;
          this.childList = res.data.map((item) => {
            return item.child;
          });
        }
      );
    },
    detBtn(item) {
      this.letter = item.letter;
      this.childList = item.child;
      this.iconDown = false;
      this.isBox = true;
    },
    // 查看详情
    dataIdBtn(dataId) {
      contentDetailsApi({ dataId: dataId, toolCode: "medicineFind" }).then(
        (res) => {
          this.isShow = false;
          this.isShowDetail = true;
          this.detailShow = res.data;
          console.log("resresres", res);
        }
      );
    },
    // 返回上一层
    returnBtn() {
      this.isShow = true;
      this.isShowDetail = false;
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.main {
  height: 695px;
  overflow: auto;
  background: #ffffff;
  .cut_off {
    border-bottom: 1px solid #ffa54b;
  }
  .br-bot {
    border-bottom: 1px solid #e2e2e2;
  }
  .s_box {
    display: flex;
    flex-wrap: wrap;
    .single {
      margin-right: 26px;
      margin-bottom: 5px;
      margin-top: 5px;
      font-size: 14px;
      color: #999999;
      text-align: center;
      line-height: 36px;
      width: 120px;
      height: 36px;
      background: #f9f9f9;
      border-radius: 4px;
    }
  }
  .zm_box {
    display: flex;
    flex-wrap: wrap;
    .a_con {
      width: 50px;
      height: 30px;
      text-align: center;
      line-height: 30px;
    }
  }
}
</style>